<template>
  <div class="about">
    <h1>バックアップファイル ダウンロード</h1>
    <v-card class="pa-5" flat outlined>
      <v-card-actions>
        <v-spacer></v-spacer>
        <Btn icon="send" color="success" @click="backup()">ダウンロード</Btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
// VueGoodTable
import "vue-good-table/dist/vue-good-table.css";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";

const DATE = new Date();

export default {
  name: "BackupOutput",
  mixins: [Common, Forms, ShowDialogs, Api],
  components: {},
  computed: {},
  methods: {
    async backup() {
      //if (!this.$refs.form.validate()) return;
      console.log("backup:download", "start");

      const confirm = await this.$confirm(
        "バックアップファイルをダウンロードしますか？",
        "ダウンロード"
      );
      if (!confirm) return;
      this.$loading();

      try {
        const param = {
          test: ""
        };

        const encode = encodeURI(JSON.stringify(param));

        const result = await this.$downloadTxt("backup", "query=" + encode);

        if (result.size == 0) {
          alert("該当するデータがありません。");
          return;
        }

        const url = URL.createObjectURL(result);
        console.log(result);
        const link = document.createElement("a");
        link.href = url;
        link.download = "backup.zip";
        link.click();
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
        console.log("csv:download", "end");
      }
    }
  },
  mounted() {
    console.log("mounted");
  },
  async created() {
    console.log("created", this.date);
    this.$loading();
    try {
      //this.ckbn = await this.getCkbn();
      ////await this.getUsers();
      //// await this.getShozoku();
      //this.setdate();
    } finally {
      this.$unloading();
    }
  }
};
</script>
